<template>
    <div class="hello">
        <main role="main">
            <div class="container py-md-5 py-3 justify-content-around">
                <Splash>
                    <div slot="slogan">Company Formation</div>
                    <div slot="slogan2">Made Easy</div>
                    <div slot="slogan3">With AI assisted legal professionals, forming
                        your own company has never been easier.
                        Start your business today for as little as $98 + state fees.
                    </div>
                    <div slot="addon" class="row py-lg-5 py-3 mt-md-3 justify-content-center slogan-text">
                        <div class="col-xl-4 col-sm-6 mb-3">
                            <select class="custom-select d-block clickable" v-model="type">
                                <option value="">Entity Type</option>
                                <Option value="LLC">LLC</Option>
                                <Option value="CCORP">C-Corporation</Option>
                            </select>
                        </div>
                        <div class="col-xl-4 col-sm-6 mb-3">
                            <select class="custom-select d-block clickable" v-model="state">
                                <Option value="">State of Formation</Option>
                                <Option value="CA">CA</Option>
                                <Option value="DE">DE</Option>
                            </select>
                        </div>
                        <div class="col-xl-4 col-8 mb-3 text-center">
                            <button class="btn px-4 py-2 font-weight-bold" @click="toPricing">GET STARTED</button>
                        </div>
                    </div>
                    <div slot="banner">
                        <img class="img-fluid" src="@/assets/home-head.png">
                    </div>

                </Splash>

            </div>
            <div class="odd-section py-3 py-md-5">
                <div class="container">
                    <h4 class="font-weight-bolder py-md-3 py-3 sec-title">Fast and Easy Process</h4>
                    <div class="row py-3 px-md-5 justify-content-around">
                        <div class="col-md-4 p-2 col-8 row justify-content-around">
                            <div class="col-xl-5 col-md-6 col-7">
                                <img class="img-fluid" src="../assets/step1.png">
                            </div>

                            <div class="col-12 font-weight-bold pt-4">
                                <div tabindex="-1" id="process1" class="step clickable">Choose your entity type</div>
                                <BPopover ref="p1pop" target="process1" triggers="focus">
                                    <div>
                                        <div class="mb-2">
                                            Step 1
                                            <button type="button" class="close" aria-label="Close"
                                                    @click="$refs.p1pop.$emit('close')">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <ul class="bullet">
                                            <li>
                                                Are you a start-up and plan to get funding from investors in the future?
                                                <br>
                                                <strong>Yes – C-Corporation</strong>
                                            </li>
                                            <li>
                                                Are you a small business owner, self-employed and plan to work with
                                                family member or close friends? <br>
                                                <strong>Yes – LLC</strong>
                                            </li>
                                        </ul>
                                    </div>
                                </BPopover>
                            </div>
                        </div>
                        <div class="col-md-4 p-2 col-8 row justify-content-around">
                            <div class="col-xl-5 col-md-6 col-7">
                                <img class="img-fluid" src="../assets/step2.png">
                            </div>
                            <div class="col-12 font-weight-bold pt-4 ">
                                <div tabindex="-1" id="process2" class="step clickable">Fill in your personal
                                    information
                                </div>
                            </div>
                            <BPopover ref="p2pop" target="process2" triggers="focus">
                                <div>
                                    <div class="mb-2">
                                        Step 2
                                        <button type="button" class="close" aria-label="Close"
                                                @click="$refs.p2pop.$emit('close')">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <ul class="bullet">
                                        <li>
                                            We just need your name, email, phone num-ber, and personal address.
                                        </li>
                                    </ul>
                                </div>
                            </BPopover>
                        </div>
                        <div class="col-md-4 p-2 col-8 row justify-content-around">
                            <div class="col-xl-5 col-md-6 col-7">
                                <img class="img-fluid" src="../assets/step3.png">
                            </div>
                            <div class="col-12 font-weight-bold pt-4">
                                <div tabindex="-1" id="process3" class="step clickable">Fill in your company
                                    information
                                </div>
                            </div>
                            <BPopover ref="p3pop" target="process3" triggers="focus">
                                <div>
                                    <div class="mb-2">
                                        Step 3
                                        <button type="button" class="close" aria-label="Close"
                                                @click="$refs.p3pop.$emit('close')">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <ul class="bullet">
                                        <li>
                                            Tell us your company name, business type/purpose/address and member
                                            information
                                        </li>
                                    </ul>
                                </div>
                            </BPopover>
                        </div>
                    </div>
                    <div class="py-3">
                        <div class="btn px-5 py-3 my-5 font-weight-bolder slogan-text" @click="$router.push('/pricing')"
                             role="button">GET STARTED
                        </div>
                    </div>
                </div>
            </div>
            <div class="container py-md-5 py-3">
                <div>
                    <h4 class="font-weight-bolder py-3 sec-title"><a class="text-decoration-none">More than just an
                        online filing platform</a></h4>
                    <div id='care' class="row justify-content-around">
                        <div class="col-lg-6 row py-2 py-lg-5 justify-content-around">
                            <div class="col-sm-3">
                                <img class="p-2 img-fluid" src="../assets/benefit3.png">
                            </div>
                            <div class="col-sm-8">
                                With the great help from our AI platform, professional services will be available in
                                selected packages.
                            </div>
                        </div>
                        <div class="col-lg-6 row py-2 py-lg-5 justify-content-around">
                            <div class="col-sm-3">
                                <img class="p-2 img-fluid sec-title" src="../assets/benefit2.png">
                            </div>
                            <div class="col-sm-8">
                                We provide worry-free post-incorporation services and intuitive workflow that make
                                communication effortless.
                            </div>
                        </div>
                        <div class="col-lg-6 row py-2 py-lg-5 justify-content-around">
                            <div class="col-sm-3">
                                <img class="p-2 img-fluid" src="../assets/benefit1.png">
                            </div>
                            <div class="col-sm-8">
                                Our rich network supports startup companies. From immigration and tax planning to
                                logistics and social media marketing, everything con-nects seamlessly.
                            </div>
                        </div>
                        <div class="col-lg-6 row py-2 py-lg-5 justify-content-around">
                            <div class="col-sm-3">
                                <img class="p-2 img-fluid" src="../assets/benefit4.png">
                            </div>
                            <div class="col-sm-8">
                                In addition to quality, we do care about transparency. Because we know the pain and
                                understand the frustration of traditional legal experiences, we spend tremendous
                                engineering resources to systematically improve the transparency of the process for each
                                case.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="odd-section">
                <div class="container py-md-5 py-3">
                    <h4 class="font-weight-bolder py-3 sec-title">Testimonials</h4>
                    <div class="row justify-content-center py-md-5 py-3 text-left">
                        <div class="col-xl-4 container p-3">
                            <div>
                                <div class="mb-3 row justify-content-start align-bottom">
                                    <div class="col-3">
                                        <img class="img-fluid" src="../assets/testim1.png">
                                    </div>
                                    <div class="col-6 text-left align-self-end font-weight-bold">
                                        <div class="slogan-text">Red Cube</div>
                                        <div>Design Studio</div>
                                    </div>
                                </div>
                                <div class="font-italic">
                                    <p>
                                        “I love AILaw. I not only saved a lot of money, but also the process was more
                                        efficient in comparison to my previous experience in working with a traditional
                                        law firm. AILaw professionally reviewed everything, and I even received a phone
                                        call to correct my typos.”
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 container p-3">
                            <div>
                                <div class="mb-3 row justify-content-start align-bottom">
                                    <div class="col-3">
                                        <img class="img-fluid" src="../assets/testim2.png">
                                    </div>
                                    <div class="col-6 text-left align-self-end font-weight-bold">
                                        <div class="slogan-text">Wecash</div>
                                        <div>Digital Finance</div>
                                    </div>
                                </div>
                                <div class="font-italic">
                                    <p>
                                        “The AILaw provided me a one-stop shopping experience to complete my company
                                        registration within 10 minutes. All I had to do was fill out a simple
                                        questionnaire
                                        and leave the rest to the AI platform. ”
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 container p-3">
                            <div>
                                <div class="mb-3 row justify-content-start align-bottom">
                                    <div class="col-3">
                                        <img class="img-fluid" src="../assets/testim3.png">
                                    </div>
                                    <div class="col-6 text-left align-self-end font-weight-bold">
                                        <div class="slogan-text">Flyhigh Talent</div>
                                        <div>Recruiting</div>
                                    </div>
                                </div>
                                <div class="font-italic">
                                    <p>
                                        “AILaw Corporation is extremely startup friendly. Their legal professionals
                                        walked
                                        me through each individual step of building my business from scratch.
                                        They even offered a package that includes trademark registration and immigration
                                        solutions,
                                        which saved me time from looking into other lawyers to handle these
                                        transactions.
                                        ”
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
  import { BPopover } from 'bootstrap-vue'
  import Splash from '@/components/Block/Splash'

  export default {
    name: 'Home',
    data () {
      return {
        state: '',
        type: ''
      }
    },
    components: {Splash, BPopover},
    methods: {
      toPricing () {
        const params = {
          state: this.state,
          type: this.type
        };
        this.$router.push({
          path: '/pricing',
          query: params
        });
      }
    }
  }
</script>


<style scoped lang="scss">
    button {
        outline: none;
    }

    #care {
        text-align: center;
    }

    .slogan-text {
        font-size: 1.25rem
    }

    .sec-title {
        font-size: 1.5rem;
    }

    .step {
        border-bottom: 1px solid;
        width: fit-content;
        margin: 0 auto;
        cursor: pointer;
    }

    .large-text {
        font-size: 1.2rem;
    }

    @include media-breakpoint-up(lg) {

        .slogan-text {
            font-size: 1.25rem;
        }

        .sec-title {
            font-size: 2rem;
        }

        .large-text {
            font-size: 1.5rem;
        }

        .slogan-main {
            line-height: 1.75rem;
        }
    }

    @include media-breakpoint-up(sm) {
        #care {
            text-align: left;
        }
    }

    .hello {
        text-align: center;
    }

    .font-5 {
        font-size: 3rem;
    }

    .font-4 {
        font-size: 2rem;
    }

    .font-2 {
        font-size: 1rem;
    }

    .line-h-3 {
        line-height: 2rem;
    }

    .odd-section {
        background-color: rgba(235, 244, 247, 1.0);;
    }

    .feature {
        width: 100%;
        vertical-align: center;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        font-weight: bold;
    }

    .featurette-heading {
        margin: 0 2rem .5rem;
    }

    .bullet li {
        list-style: square inside url(../assets/arrow.png);
        display: list-item;
    }
</style>
