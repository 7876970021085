<template>
    <div class="container row align-items-center justify-content-lg-around justify-content-center">
        <div class="col-lg-7 text-left col-12">
            <div class="font-weight-bolder py-md-3 pr-lg-2 slogan">
                <div class="slogan">
                    <slot name="slogan"></slot>
                </div>
                <div class="slogan2">
                    <slot name="slogan2"></slot>
                </div>
            </div>
            <h4 class="py-3 slogan-text slogan-main"><slot name="slogan3"></slot></h4>
            <slot name="addon"></slot>
        </div>
        <div class="col-lg-5 col-10">
            <slot name="banner"></slot>
        </div>
    </div>
</template>

<script>
  export default {
    name: "Splash"
  }
</script>

<style scoped lang="scss">
    .slogan {
        font-size: 2.5rem;
    }

    .slogan2 {
        font-size: 2rem;
    }

    .slogan-text{
        font-size: 1.25rem
    }


    @include media-breakpoint-up(lg) {
        .slogan {
            font-size: 3.75rem;
        }

        .slogan2 {
            font-size: 3rem;
        }

        .slogan-text{
            font-size: 1.25rem;
        }

        .slogan-main{
            line-height: 1.75rem;
        }
    }
</style>
